// extracted by mini-css-extract-plugin
export var root = "Contact-module--root--2dTpX";
export var loading = "Contact-module--loading--14spr";
export var spin = "Contact-module--spin--jhGnG";
export var send = "Contact-module--send--1BfwR";
export var content = "Contact-module--content--1nE18";
export var outline = "Contact-module--outline--3ZkZ0";
export var active = "Contact-module--active--3KzEt";
export var categories = "Contact-module--categories--3aI_N";
export var input = "Contact-module--input--12gTt";
export var textarea = "Contact-module--textarea--1KV4r";
export var error = "Contact-module--error--3hp2t";