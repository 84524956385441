import React from 'react'

import Layout from '~components/Layout'
import ContactTemplate from '~templates/Contact';

export default function IndexPage() {
  return (
    <Layout>
      <ContactTemplate />
    </Layout>
  )
}
