import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Formik } from 'formik';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'
import TextareaAutosize from 'react-textarea-autosize';
import Loading from '~images/loading.svg'

import * as styles from './Contact.module.scss'
import { init, send } from 'emailjs-com';
import Button from '~components/Button';
import { title } from '~templates/Works/components/Works.module.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import FileUploader from '~components/FileUploader';
import { graphql, Link, useStaticQuery } from 'gatsby';

const EMAIL_JS_ID = process.env.GATSBY_EMAIL_JS_ID;
const EMAIL_JS_SERVICE_ID = process.env.GATSBY_EMAIL_JS_SERVICE_ID;
const EMAIL_JS_TEMPLATE_ID = process.env.GATSBY_EMAIL_JS_TEMPLATE_ID;

const ContactTemplate: FC<unknown> = (): ReactElement => {
  const data = useStaticQuery(query);
  const [isSent, setIsSent] = useState(false);
  const budgets = data.allContentfulBudgets?.edges.map(edge => edge.node);
  const services = data.allContentfulProjectServices?.edges.map(edge => edge.node);
  const pageData = data.allContentfulStartProjectPage?.edges[0].node;

  useEffect(() => {
    init(EMAIL_JS_ID);
  }, []);

  const sendEmail = (templateParams, setSubmitting) => {
    send(EMAIL_JS_SERVICE_ID, EMAIL_JS_TEMPLATE_ID, templateParams)
      .then(function(response) {
        setSubmitting(false);
        setIsSent(true)
      }, function(error) {
        setSubmitting(false);
        setIsSent(false);
        alert(error)
      });
  }

  const selectCategory = (selectedCategories, cat) => {
    const newCat = [...selectedCategories];
    const index = selectedCategories.indexOf(cat);
    if (index === -1) {
      newCat.push(cat);
    } else {
      newCat.splice(index, 1)
    }
    return newCat
  }

  return (
    <div className={styles.root}>
      <div>
        {!isSent && <h1 className={title}>
          {documentToReactComponents(JSON.parse(pageData.richTitle.raw))}
        </h1>}
        <div className={`${styles.content} ${isSent ? styles.isSent : ``}`}>
          {isSent && documentToReactComponents(JSON.parse(pageData.successRich.raw))}
          {!isSent && <Formik
            initialValues={{ category: [], name: '', email: '', phone: '', message: '', file: null, budget: '' }}
            validate={values => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              if (!values.name) {
                errors.name = 'Required';
              }
              // if (!values.category.length) {
              //   errors.category = 'Required';
              // }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              sendEmail({
                name: values.name,
                email: values.email,
                phone: values.phone,
                message: values.message,
                budget: values.budget,
                services: values.category.map((cat, index) => `${cat}`)
              }, setSubmitting);
              // setTimeout(() => {
              //   alert(JSON.stringify(values, null, 2));
              //   // alert(
              //   //   JSON.stringify(
              //   //     {
              //   //       fileName: values.file.name,
              //   //       type: values.file.type,
              //   //       size: `${values.file.size} bytes`
              //   //     },
              //   //     null,
              //   //     2
              //   //   )
              //   // );
              //   setSubmitting(false);
              // }, 400);
            }}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
              <form onSubmit={handleSubmit}>
                <h2>{pageData.interested}</h2>
                <div className={styles.categories}>
                  {services.map((cat, index) => {
                    const isActive = values.category.indexOf(cat.title) !== -1;
                    return (
                      <Button
                        key={index}
                        className={`${styles.outline} ${isActive ? styles.active : ''}`}
                        label={cat.title}
                        handleClick={(e) => {setFieldValue("category", selectCategory(values.category, cat.title))}} />
                    )
                  })}
                </div>
                <input
                  type="name"
                  name="name"
                  placeholder='Your name'
                  className={styles.input}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                <span className={styles.error}>
                {errors.name && touched.name && errors.name}
              </span>
                <input
                  type="email"
                  name="email"
                  placeholder='Your email'
                  className={styles.input}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <span className={styles.error}>
                {errors.email && touched.email && errors.email}
              </span>
                {/*<PhoneInput*/}
                {/*  className={styles.input}*/}
                {/*  placeholder='Your phone'*/}
                {/*  name='phone'*/}
                {/*  value={values.phone}*/}
                {/*  onChange={e => setFieldValue("phone", e)}*/}
                {/*  onBlur={handleBlur("phone")}*/}
                {/*/>*/}
                <input
                  type="phone"
                  name="phone"
                  placeholder='Your phone or skype'
                  className={styles.input}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                />
                <span className={styles.error}>
                {errors.phone && touched.phone && errors.phone}
              </span>

                <TextareaAutosize
                  minRows={1}
                  maxRows={6}
                  placeholder='Message or question'
                  name='message'
                  className={`${styles.input} ${styles.textarea}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
                <span className={styles.error}>
                {errors.message && touched.message && errors.message}
              </span>

                {/*<FileUploader handleFile={(file) => { setFieldValue("file", file) }} />*/}

                <h2>{pageData.budget}</h2>
                <div className={styles.categories}>
                  {budgets.sort((a: any, b: any) => a.order - b.order).map((cat, index) => {
                    const isActive = values.budget === cat.title;
                    return (
                      <Button
                        key={index}
                        className={`${styles.outline} ${isActive ? styles.active : ''}`}
                        label={cat.title}
                        handleClick={(e) => {setFieldValue("budget", cat.title)}} />
                    )
                  })}
                </div>

                <div className={styles.send}>
                  <Button type='submit' label={pageData.send} disabled={isSubmitting} icon={isSubmitting ? <img src={Loading} className={styles.loading} /> : null} />
                  <div>
                    {pageData.showConsent && documentToReactComponents(JSON.parse(pageData.consent.raw))}
                  </div>
                </div>
              </form>
            )}
          </Formik>}
        </div>
      </div>
    </div>
  )
}

export default ContactTemplate;

export const query = graphql`
query allContentfulContactSectionsQuery {
  allContentfulBudgets(sort: {fields: order, order: ASC}) {
    edges {
      node {
        order
        title
      }
    }
  }
  allContentfulProjectServices {
    edges {
      node {
        title
      }
    }
  }
  allContentfulStartProjectPage {
    edges {
      node {
        richTitle {
          raw
        }
        budget
        interested
        send
        successRich {
          raw
        }
        showConsent
        consent {
          raw
        }
      }
    }
  }
}
`
